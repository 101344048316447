define("discourse/plugins/discourse-activity-pub/discourse/controllers/admin-plugins-activity-pub-actor", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _tracking, _controller, _object, _computed, _service, _ajax, _ajaxError, _I18n, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminPluginsActivityPubActor = _exports.default = (_dec = (0, _controller.inject)("adminPlugins.activityPub.actorShow"), _dec2 = (0, _computed.notEmpty)("actors"), (_class = class AdminPluginsActivityPubActor extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "actorShowController", _descriptor2, this);
      _initializerDefineProperty(this, "order", _descriptor3, this);
      _initializerDefineProperty(this, "asc", _descriptor4, this);
      _initializerDefineProperty(this, "model_type", _descriptor5, this);
      _defineProperty(this, "loadMoreUrl", "");
      _defineProperty(this, "total", "");
      _initializerDefineProperty(this, "hasActors", _descriptor6, this);
      _defineProperty(this, "queryParams", ["model_type", "order", "asc"]);
    }
    get title() {
      return _I18n.default.t(`admin.discourse_activity_pub.actor.${this.model_type}.title`);
    }
    loadMore() {
      if (!this.loadMoreUrl || this.total <= this.actors.length) {
        return;
      }
      this.set("loadingMore", true);
      return (0, _ajax.ajax)(this.loadMoreUrl).then(response => {
        if (response) {
          this.actors.pushObjects((response.actors || []).map(actor => {
            return _activityPubActor.default.create(actor);
          }));
          this.setProperties({
            loadMoreUrl: response.meta.load_more_url,
            total: response.meta.total,
            loadingMore: false
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    addActor() {
      this.router.transitionTo("adminPlugins.activityPub.actorShow", _activityPubActor.newActor).then(() => {
        this.actorShowController.set("showForm", false);
      });
    }
    editActor(actor) {
      this.router.transitionTo("adminPlugins.activityPub.actorShow", actor).then(() => {
        this.actorShowController.set("showForm", true);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "actorShowController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "order", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "asc", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model_type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "category";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasActors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addActor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addActor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editActor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editActor"), _class.prototype)), _class));
});