define("discourse/plugins/discourse-activity-pub/discourse/routes/admin-plugins-activity-pub-actor-show", ["exports", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _ajax, _discourse, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsActivityPubActorShowRoute extends _discourse.default {
    model(params) {
      if (params.actor_id && params.actor_id !== _activityPubActor.newActor.id) {
        return (0, _ajax.ajax)(`/admin/plugins/ap/actor/${params.actor_id}`);
      } else {
        return _activityPubActor.newActor;
      }
    }
    setupController(controller, model) {
      const props = {
        actor: _activityPubActor.default.create(model),
        showForm: model.id !== _activityPubActor.newActor.id
      };
      controller.setProperties(props);
    }
  }
  _exports.default = AdminPluginsActivityPubActorShowRoute;
});