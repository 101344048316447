define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-category-chooser", ["exports", "select-kit/components/category-chooser"], function (_exports, _categoryChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _categoryChooser.default.extend({
    classNames: ["activity-pub-category-chooser"],
    selectKitOptions: {
      allowUncategorized: false
    },
    categoriesByScope() {
      return this._super().filter(category => {
        if (category.read_restricted) {
          return false;
        }
        if (this.selectKit.options.hasActor) {
          return category.activity_pub_actor_exists;
        } else {
          return !category.activity_pub_actor_exists;
        }
      });
    }
  });
});